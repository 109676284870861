import popupimg from "../../Assets/popupimg.jpg";
import arrow from "../../Assets/arrow.svg";
import close from "../../Assets/close.svg";
import { useState } from "react";

const Popup = ({ closeFunc }) => {
  const apiKey = process.env.REACT_APP_APIKEY;
  const listId = process.env.REACT_APP_LISTID;

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const subscribeToKlaviyo = async () => {
    try {
      const response = await fetch(
        "https://serverapi-lhg3.onrender.com/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            apiKey: apiKey,
            listId: listId,
            email: email,
          }),
        }
      );

      if (response.ok) {
        // Successful subscription
        console.log("Subscription successful");
      } else {
        // Handle error
        console.error("Subscription failed");
      }
    } catch (error) {
      console.error("Error subscribing:", error.message);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = () => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Perform validation if needed
    const state = validateEmail(email) !== null;
    setIsValid(validateEmail(email) !== null);

    if (!state) {
      return;
    }

    // Subscribe to Klaviyo
    subscribeToKlaviyo();

    // Close the popup
    closeCard();
  };

  const closeCard = () => {
    closeFunc(false);
  };

  return (
    <>
      <div
        onClick={closeCard}
        className="z-[100] fixed flex w-full h-screen justify-center items-center"
      ></div>
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[101] flex flex-col max-w-[500px] w-full h-screen xs:h-[651px] bg-[#2D3745]">
        <button
          onClick={closeCard}
          className="flex justify-center items-center sm:w-[40px] sm:h-[40px] w-[24px] h-[24px] absolute top-[16px] right-[16px] sm:right-[6px] sm:top-[6px]"
        >
          <img
            src={close}
            alt=""
            className="sm:h-[22.94px] sm:w-[22.94px] w-[14px] h-[14px]"
          />
        </button>

        <img
          src={popupimg}
          alt=""
          className=" max-h-[375px]"
        />
        {/* Text */}
        <div className="px-[16px] sm:px-[0px] w-full flex justify-center py-[20px]">
          <div className="max-w-[460px] flex flex-col gap-[20px]">
            <h2 className="text-[#fff] text-[36px] leading-[40px] tracking-[0.72px]">
              Sign up to receive news and updates from WAF
            </h2>

            <p className="text-white tracking-[0.48px] leading-[20px] text-[16px]">
              We’ll share news about product launches, mixes, and events—at a
              palatable frequency
            </p>

            <div className="w-full flex h-[36px] border rounded-[4px] border-[#CFC8C6]">
              <input
                onChange={handleEmailChange}
                type="text"
                className={`${
                  isValid ? "" : "text-red-200"
                } w-full bg-transparent outline-none py-[10px] px-[12px] leading-[16px] text-[13px] tracking-[0.65px] text-[#CFC8C6] placeholder:text-[#CFC8C6] placeholder:tracking-[0.65px] placeholder:leading-[16px] placeholder:text-[13px]`}
                placeholder="Your email"
              />
              <button
                onClick={handleFormSubmit}
                className="text-white w-[65px] border-[#CFC8C6] border-l text-[13px] leading-[16px]"
              >
                Submit
              </button>
            </div>

            <button
              onClick={closeCard}
              className="w-fit flex items-center text-[#E8E8E3] gap-[4px] tracking-[0.56px] leading-[18px] text-[14px]"
            >
              No thanks
              <img
                src={arrow}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
